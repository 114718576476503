/* eslint-disable class-methods-use-this */
import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

export default class MagicLinkForm extends Form {
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
      }),
    };
  }

  setup() {
    return {
      fields: [{
        name: 'email',
        label: 'Verify your email',
        placeholder: '',
        rules: 'required|email|string',
        value: '',
        handlers: {
          onChange: field => (e) => {
            const lowercase = e.target.value.toLowerCase();
            field.set(lowercase);
          },
        },
      }],
    };
  }

  options() {
    return {
      // validateOnChange: true,
      validateOnBlur: true,
    };
  }

  hooks() {
    return {
      onSuccess() {
      },
      onError() {
      },
    };
  }
}

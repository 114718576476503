import React, { Component } from 'react';

export default class ModalCheckmarkIcon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
      >
        <defs>
          <rect id="path-1" x="0" y="0" width="1440" height="1520" />
          <rect id="path-3" x="0" y="0" width="600" height="456" rx="20" />
          <filter x="-10.8%" y="-12.1%" width="121.7%" height="128.5%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feColorMatrix values="0 0 0 0 0.235294118   0 0 0 0 0.223529412   0 0 0 0 0.435294118  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" />
          </filter>
          <circle id="path-5" cx="10" cy="10" r="10" />
        </defs>
        <g id="FAHRI-UPDATES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="11-13-Sunrise,-Shop-/-Desktop-v1" transform="translate(-900.000000, -530.000000)">
            <rect fill="#191941" x="0" y="0" width="1800" height="1222" />
            <g id="section-:-membership-2" transform="translate(0.000000, 80.000000)">
              <g id="section-:-membership">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use id="bg" fill="#F5F5F5" xlinkHref="#path-1" />
                <line x1="90" y1="466" x2="1350" y2="466" id="-----" stroke="#DBDBDB" strokeLinecap="square" fillRule="nonzero" mask="url(#mask-2)" />
              </g>
            </g>
            <g id="Magic-link-popup-Thank-you" transform="translate(0.000000, 1.000000)">
              <rect id="overlay-sign-in-popup" fillOpacity="0.5" fill="#191941" fillRule="nonzero" x="0" y="0" width="1440" height="1324" />
              <g id="popup" transform="translate(420.000000, 294.000000)" fillRule="nonzero">
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-3" />
                  <use fill="#FFFFFF" xlinkHref="#path-3" />
                </g>
              </g>
              <g id="input-/-flat-/-correct" transform="translate(500.000000, 500.000000)">
                <rect id="bg" fill="#FFFFFF" fillRule="nonzero" x="0" y="0" width="440" height="60" />
                <g id="gfx-/-icon-/-feedback-correct-gfx_icons-/-icon-/-feedback-correct" transform="translate(400.000000, 29.000000)">
                  <g id="icon-check">
                    <mask id="mask-6" fill="white">
                      <use xlinkHref="#path-5" />
                    </mask>
                    <use id="Mask" fill="#17E3AE" fillRule="nonzero" xlinkHref="#path-5" />
                    <polyline id="Path-5" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" fillRule="nonzero" mask="url(#mask-6)" points="5.64656576 9.41080729 9.36767578 13.0570475 18.3036296 4.47184245" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import IconLoading from 'components/icons/Loading';
import ModalCheckmarkIcon from 'components/icons/ModalCheckmarkIcon';
import { useTranslation } from 'server/i18n';

import css from './style.css';

export default observer(({
  field,
  loading = false,
  success = false,
  error = false,
  customErrorText,
  isDisabled = false,
  props,
}) => {
  const { t } = useTranslation();
  const label = t(field.label, field.label);
  const sError = field.error === null || field.error === undefined || field.error === '' ? field.error : field.error.replace(field.label, label);
  const tError = sError && t(`forms:errors.${sError.replace('.', '')}`, sError);
  const optional = t('forms:common.optional', 'optional');

  return (
    <div className={cx({
      [css['input-text-with-loader']]: true,
      [css['input-text-with-loader-required']]: field.rules.includes('required'),
    }, success ? 'input-text-Success' : ' ', ((field.isTouched && field.isInvalid) || (field.error) || (!field.isEmpty && error)) ? 'input-text-hasError' : ' ')}
    >
      <label htmlFor={field.id}>
        <span
          data-content={` (${optional})`}
          className={css['input-text-with-loader-label']}
        >
          {field.label}
        </span>
        <input className={cx('accent-text-color')} {...props} {...field.bind()} disabled={isDisabled} />
        <span className={cx(css['input-text-with-loader-state-icon'])}>
          {
            (loading && (
              <IconLoading />
            )) || (success && (
              <ModalCheckmarkIcon />
            ))
          }
        </span>
        {((field.isTouched && field.isInvalid) || (field.error) || (!field.isEmpty && error)) && (
          <span id={`${field.name}__errorMessage`} className={css['input-text-error']}>{error ? customErrorText : (tError || field.error)}</span>
        )}
      </label>
    </div>
  );
});
